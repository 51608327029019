/** @jsxImportSource @emotion/react */

export const List = ({ orderList, showNoData, getNextPage }) => {
  //判断滑动到底部
  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target

    if (scrollHeight - scrollTop === clientHeight) {
      getNextPage()
    }
  }

  return (
    <ul
      css={{
        width: '100%',
        height: '400px',
        overflow: 'auto',
        marginTop: '32px',
        li: {
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '3px 16px',
          fontSize: '12px',
        },
      }}
      onScroll={(e) => {
        handleScroll(e)
      }}
    >
      {orderList.map((item, index) => (
        <li key={index}>
          <span
            css={{
              width: '120px',
            }}
          >
            {item.id}
          </span>
          <span
            css={{
              width: '50px',
              margin: '0 5px',
              textAlign: 'center',
              color: item.statusCode === 0 ? 'green' : 'red',
            }}
          >
            {item.status}
          </span>
          <span
            css={{
              flex: '1',
              textAlign: 'center',
              marginRight: '5px',
            }}
          >
            {item.area}
          </span>
          <span
            css={{
              width: '35px',
            }}
          >
            {item.time}
          </span>
        </li>
      ))}
      <p
        css={{
          color: '#BFBEBF',
          fontSize: '12px',
          textAlign: 'center',
          marginTop: '6px',
        }}
      >
        {showNoData || orderList.length < 20
          ? '----------  没有更多数据了  ----------'
          : '数据加载中......'}
      </p>
    </ul>
  )
}
