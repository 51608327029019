/** @jsxImportSource @emotion/react */
import { useHistory } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import { useEffect, useState } from 'react'
import { Input } from './Input'
import { List } from './List'

export const TypeList = ({ type, title, APIUrl }) => {
  const history = useHistory()
  const [orderList, setOrderList] = useState([])
  const [currentPage, setCurrentPage] = useState(0)
  const [showNoData, setShowNoData] = useState(false)

  // 获取当前所有列表
  const allOrderList = localStorage[`${type}List`]
    ? JSON.parse(localStorage[`${type}List`])
    : []

  useEffect(() => {
    getNextPage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type])

  // 更新列表
  const updateOrderList = (item) => {
    let newOrderList = []

    newOrderList = item ? [item, ...orderList] : orderList

    if (item) {
      let localOrderList = [
        item,
        ...JSON.parse(localStorage[`${type}List`] || '[]'),
      ]

      if (localOrderList.length > 160) {
        localOrderList = localOrderList.slice(0, 160)
      }

      localStorage[`${type}List`] = JSON.stringify(localOrderList)
    }

    setOrderList(newOrderList)
  }

  // 获取第一页数据/下一页数据
  const getNextPage = () => {
    if (
      JSON.parse(localStorage[`${type}List`] || '[]').length / 20 <=
      currentPage
    ) {
      setShowNoData(true)
      return
    }

    const currentPageOrderList = allOrderList.slice(
      currentPage * 20,
      (currentPage + 1) * 20
    )

    setOrderList(orderList.concat(currentPageOrderList))
    setCurrentPage(currentPage + 1)
  }

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100vw',
        height: '100vh',
      }}
    >
      <Button
        css={{
          color: '#101010',
          borderColor: '#101010',
          position: 'absolute',
          top: '8px',
          left: '8px',
        }}
        onClick={() => {
          history.go(-1)
        }}
        variant="outlined"
        color="primary"
      >
        返回
      </Button>

      <h1
        css={{
          marginTop: '64px',
        }}
      >
        {title}
      </h1>

      <Input APIUrl={APIUrl} updateOrderList={updateOrderList} />

      {orderList.length > 0 ? (
        <List
          orderList={orderList}
          getNextPage={getNextPage}
          showNoData={showNoData}
        />
      ) : (
        <div
          css={{
            marginTop: '20px',
            color: '#BFBEBF',
            fontSize: '14px',
            textAlign: 'center',
          }}
        >
          暂无数据
        </div>
      )}
    </div>
  )
}
