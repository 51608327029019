/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import { mainColor } from '../../plugins/style'
import Button from '@material-ui/core/Button';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import { useHistory } from 'react-router-dom'
import { loginAPI } from '../../plugins/apis.js'

export const Login = () => {
  const history = useHistory()
  const [userName, setUserName] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false);
  const [toastConfig, setToastConfig] = useState({
    toastStatus: false,
    toastMessage: '登陆失败'
  })

  return (
    <div css={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100vw',
      height: '100vh'
    }}>
      <h1>NUTRIPACKS</h1>
      <h3>后台管理系统</h3>

      <TextField
        css={{
          width: '240px',
          marginBottom: '20px'
        }}
        label="用户名"
        color="secondary"
        type="text"
        value={userName}
        onChange={(event) => setUserName(event.target.value)}
      />


      <TextField
        css={{
          width: '240px',
          marginBottom: '20px'
        }}
        label="密码"
        color="secondary"
        type={showPassword ? 'text' : 'password'}
        value={password}
        onChange={(event) => setPassword(event.target.value)}
        InputProps={{
          endAdornment: <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }}
      />

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={() => {
          setToastConfig({ ...toastConfig, toastStatus: false })
        }}
        open={toastConfig.toastStatus}
        autoHideDuration={1000}
        message={toastConfig.toastMessage}
      />

      <Button css={{
        width: '100px',
        marginTop: '50px',
        backgroundColor: mainColor,
        '&:hover': {
          backgroundColor: mainColor
        }
      }} variant="contained" color="primary" onClick={() => {
        if (!userName) {
          setToastConfig({ toastStatus: true, toastMessage: '请输入用户名' })
          return
        }

        if (!password) {
          setToastConfig({ toastStatus: true, toastMessage: '请输入密码' })
          return
        }

        loginAPI(userName, password).then(({ code, msg, data }) => {
          if (code !== 0) {
            setToastConfig({ toastStatus: true, toastMessage: msg })
            return
          }

          sessionStorage.userInfo = JSON.stringify(data)

          history.push('/home-page')
        })
      }}>登陆</Button>
    </div>
  )
}
