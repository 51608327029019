/** @jsxRuntime classic */
/** @jsx jsx */
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { jsx } from '@emotion/react'
import { StylesProvider } from '@material-ui/core/styles';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom"
import { Login } from './pages/Login/Index'
import { HomePage } from './pages/HomePage/Index'
import { OrderList } from './pages/OrderList/Index'
import { SortingList } from './pages/SortingList/Index'
// import VConsole from 'vconsole'

// if (process.env.REACT_APP_BASE_URL === 'https://dev.xiaojudai.cn/') {
//   new VConsole()
// }

ReactDOM.render(
  <StylesProvider injectFirst>
      <Router>
        <Switch>
          <Route path="/" exact component={Login} />
          <Route path="/home-page" exact component={HomePage} />
          <Route path="/sorting" component={SortingList} />
          <Route path="/list/:type" component={OrderList} />
        </Switch>
      </Router>
  </StylesProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
