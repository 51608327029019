/** @jsxImportSource @emotion/react */
import { useHistory, useParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { TypeList } from './TypeList'

export const OrderList = () => {
  const { type } = useParams()
  const history = useHistory()
  const [APIUrl, setAPIUrl] = useState('')
  const [title, setTitle] = useState('')

  useEffect(() => {
    switch (type) {
      case 'transfer':
        setTitle('分装间移交')
        setAPIUrl('/pda/finishRoom/transfer')
        break
      case 'receive':
        setTitle('成品间接收')
        setAPIUrl('/pda/finishRoom/receive')
        break
      case 'nuclearNote':
        setTitle('核注')
        setAPIUrl('/pda/finishRoom/nuclearNote')
        break
      case 'ship':
        setTitle('发货')
        setAPIUrl('/pda/finishRoom/ship')
        break
      case 'binding':
        setTitle('订单变更货架')
        setAPIUrl('/pda/Finishroom/setShelvesNo')
        break
      default:
        history.replace('/home-page')
        break
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type])

  return (
    <React.Fragment>
      <TypeList type={type} APIUrl={APIUrl} title={title} />
    </React.Fragment>
  )
}
