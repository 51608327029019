/** @jsxImportSource @emotion/react */
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import TextField from '@material-ui/core/TextField'
import Snackbar from '@material-ui/core/Snackbar'
import Modal from '@material-ui/core/Modal'
import Button from '@material-ui/core/Button'
import ErrorIcon from '@material-ui/icons/Error'
import request from '../../plugins/request'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { mainColor } from '../../plugins/style'

// const codeMap = {
//   transfer: {
//     0: '移交成功',
//     '-1': '移交失败',
//     '-3': '重复扫码',
//     '-4': '无效单号',
//     '-5': '放退货区',
//     '-6': '放回收区',
//     '-7': '寄回总部',
//   },
//   receive: {
//     0: '接收成功',
//     '-1': '接收失败',
//     '-3': '重复扫码',
//     '-4': '无效单号',
//     '-5': '放退货区',
//     '-6': '放回收区',
//     '-7': '寄回总部',
//   },
//   nuclearNote: {
//     0: '核注成功',
//     '-1': '核注失败',
//     '-3': '重复扫码',
//     '-4': '无效单号',
//     '-5': '放退货区',
//     '-6': '放回收区',
//     '-7': '寄回总部',
//   },
//   ship: {
//     0: '发货成功',
//     '-1': '发货失败',
//     '-3': '重复扫码',
//     '-4': '无效单号',
//     '-5': '放退货区',
//     '-6': '放回收区',
//     '-7': '寄回总部',
//   },
//   binding: {
//     0: '绑定成功',
//     '-1': '绑定失败',
//     '-2': '绑定失败',
//     '-3': '绑定失败',
//     '-4': '无效单号',
//   },
// }

export const Input = ({ APIUrl, updateOrderList }) => {
  const { type } = useParams()
  const history = useHistory()
  const [orderNumber, setOrderNumber] = useState('')
  const [shelvesNumber, setShelvesNumber] = useState('')
  const [inputValue, setInputValue] = useState('')
  const [shelvesNumberInputModalStatus, setShelvesNumberInputModalStatus] =
    useState(false)
  const [modalConfig, setModalConfig] = useState({
    modalStatus: false,
    modalReason: '未知原因',
  })
  const [toastConfig, setToastConfig] = useState({
    toastStatus: false,
    toastMessage: '登陆失败',
  })

  return (
    <React.Fragment>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={() => {
          setToastConfig({ ...toastConfig, toastStatus: false })
        }}
        open={toastConfig.toastStatus}
        autoHideDuration={1500}
        message={toastConfig.toastMessage}
      />

      <Modal
        open={modalConfig.modalStatus}
        onClose={() => setModalConfig({ ...modalConfig, modalStatus: false })}
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#fff',
            width: '300px',
            height: '300px',
            borderRadius: '10px',
            padding: '20px',
          }}
        >
          <ErrorIcon color="secondary" style={{ fontSize: 100 }} />
          <h2
            css={{
              marginBottom: '40px',
            }}
          >
            {modalConfig.modalReason}
          </h2>
          <Button
            css={{
              width: '100%',
            }}
            variant="contained"
            color="secondary"
            disableElevation
            onClick={() =>
              setModalConfig({ ...modalConfig, modalStatus: false })
            }
          >
            确定
          </Button>
        </div>
      </Modal>

      {type === 'binding' || type === 'nuclearNote' ? (
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: '20px',
          }}
        >
          <p
            css={{
              margin: '0 20px 0',
            }}
          >
            当前货架位置{' '}
            <span
              css={{
                color: mainColor,
              }}
            >
              {shelvesNumber}
            </span>
          </p>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={() => setShelvesNumberInputModalStatus(true)}
          >
            变更位置
          </Button>
        </div>
      ) : null}

      <Dialog
        open={shelvesNumberInputModalStatus}
        aria-labelledby="form-dialog-title"
        onClose={() => setShelvesNumberInputModalStatus(false)}
      >
        <DialogTitle id="form-dialog-title">请扫描货架区域码</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="货架区域码"
            type="text"
            fullWidth
            onChange={(event) => {
              setInputValue(event.target.value)
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShelvesNumberInputModalStatus(false)
            }}
            color="primary"
          >
            取消
          </Button>
          <Button
            onClick={() => {
              setShelvesNumber(inputValue)
              setShelvesNumberInputModalStatus(false)
            }}
            color="primary"
          >
            确认
          </Button>
        </DialogActions>
      </Dialog>

      <TextField
        css={{
          width: '280px',
        }}
        variant="outlined"
        label="请扫描单号"
        type="text"
        value={orderNumber}
        onChange={(event) => {
          setOrderNumber(event.target.value)
        }}
        autoFocus
        onKeyUp={(e) => {
          if (e.key === 'Enter') {
            if (!orderNumber) return

            if (
              (type === 'binding' || type === 'nuclearNote') &&
              !shelvesNumber
            ) {
              setToastConfig({
                toastStatus: true,
                toastMessage: '请先扫描货架区域码',
              })

              return
            }

            request
              .get(APIUrl, {
                params: {
                  code: orderNumber,
                  no:
                    type === 'binding' || type === 'nuclearNote'
                      ? shelvesNumber
                      : null,
                },
              })
              .then(({ code, msg, message, data = {} }) => {
                setOrderNumber('')

                const { date, shelves_no } = data

                if (code === -2) {
                  setToastConfig({ toastStatus: true, toastMessage: msg })

                  setTimeout(() => {
                    history.replace('/')
                  }, 1500)

                  return
                }

                if (code !== 0) {
                  setModalConfig({
                    modalReason: message || msg,
                    modalStatus: true,
                  })
                }

                // code === 0 ? successAudio.play() : failureAudio.play()

                updateOrderList({
                  id: orderNumber,
                  statusCode: code,
                  status: message || msg,
                  area: shelves_no || '--',
                  time: date,
                })
              })
          }
        }}
      />
    </React.Fragment>
  )
}
