import axios from 'axios'

const request = axios.create({
  baseURL: process.env.NODE_ENV === 'development'
  ? 'https://dev.xiaojudai.cn/'
  : process.env.REACT_APP_BASE_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8'
  }
})

request.interceptors.request.use(config => config, error => Promise.reject(error))

request.interceptors.response.use(
  function(response) {
    if (response.status >= 200 && response.status < 300) {
      return Promise.resolve(response.data)
    } else {
      alert('服务器错误')
      return Promise.reject(response.data)
    }
  },
  function(error) {
    alert('服务器错误')
    return Promise.reject(error)
  }
)

export default request
