import request from './request'

// 登陆
export const loginAPI = async (username, password) => {
  const data = await request.post('/pda/login/login', {
    username,
    password
  })

  return data
}

// 订单分拣成品
export const sortingProductAPI = async ({step, code = '', order_id = '', bind_data = []}) => {
  const data = await request.post('/pda/Finishroom/sortingFinishedProduct', {
    step,
    code,
    order_id,
    bind_data
  })

  return data
}
