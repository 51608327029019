/** @jsxImportSource @emotion/react */
import Button from '@material-ui/core/Button'
import { useHistory } from 'react-router-dom'

export const HomePage = () => {
  const history = useHistory()

  const historyPush = (type) => () => {
    history.push(`/list/${type}`)
  }

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100vw',
        height: '100vh',
      }}
    >
      <h1
        css={{
          margin: '0',
        }}
      >
        NUTRIPACKS
      </h1>
      <h3
        css={{
          margin: '0',
        }}
      >
        你好，{JSON.parse(sessionStorage.userInfo).user_name}
      </h3>

      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          marginTop: '10px',
          button: {
            width: '100px',
            height: '100px',
            margin: '10px 30px 0',
          },
        }}
      >
        <Button variant="outlined" onClick={historyPush('transfer')}>
          分装间移交
        </Button>
        <Button variant="outlined" onClick={historyPush('receive')}>
          成品间接收
        </Button>
        <Button variant="outlined" onClick={historyPush('nuclearNote')}>
          核注
        </Button>
        <Button variant="outlined" onClick={historyPush('ship')}>
          发货
        </Button>
        <Button variant="outlined" onClick={historyPush('binding')}>
          绑定货架位置
        </Button>
        <Button variant="outlined" onClick={() => history.push('/sorting')}>
          订单分拣成品
        </Button>
      </div>
    </div>
  )
}
